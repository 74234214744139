import React from "react";
import PropTypes from "prop-types";
import SEO from "../components/seo";
import LayoutPay from '../components/layout-pay';
import PayOfficePage from '../components/payment-office-page';

export const PaymentCoworking = (props) => (
  <>
    <SEO title="Home"/>
      <PayOfficePage type="coworking" location={props.location} />
  </>
);

PaymentCoworking.propTypes = {
  title: PropTypes.string,
}

const PaymentPage = (props) => (
  <LayoutPay page='fast-page' >
    <PaymentCoworking data={props.data} location={props.location} />
  </LayoutPay>
);

export default PaymentPage;

export const pageQuery = graphql`
    query PaymentCoworking {
        markdownRemark(frontmatter: { templateKey: { eq: "fast-page" } }) {
            frontmatter {
                mainFastSlide {
                    title,
                    subtitle,
                },
                toc {
                    text,
                    riscTitle,
                    riscDescription,
                    tocTitle,
                    tocList{
                        name,
                        hash
                    }, 
                },
                howToGet {
                    title,
                    image {
                          publicURL
                        },
                    ways {
                        way,
                        description
                    },
                },
                contacts {
                    title,
                    person {
                        name,
                        position,
                        tel {
                            code
                        },
                        email {
                            code
                        },
                    },
                },
            }
        }
    }
`
